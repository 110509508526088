<template>
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <h1 class="tag is-large is-rounded has-text-weight-semibold is-size-3 mt-5">Pick a card!</h1>
    <div class="has-text-centered has-text-white mt-4">Card will be auto-selected if you leave this page.</div>
    <div class="cards-container mb-5">
      <img :src="getCardImage(0)" alt="Powerup Card" class="card-image powerup-card" @click="showConfirmScreen = true">
      <img :src="getCardImage(1)" alt="Powerup Card" class="card-image powerup-card" @click="showConfirmScreen = true">
      <img :src="getCardImage(2)" alt="Powerup Card" class="card-image powerup-card" @click="showConfirmScreen = true">
      <img :src="getCardImage(3)" alt="Powerup Card" class="card-image powerup-card" @click="showConfirmScreen = true">
    </div>
    <div
      class="modal task-modal-modifier"
      v-bind:class="{'is-active': showConfirmScreen}"
    >
      <div @click="showConfirmScreen = false" class="modal-background pointer"></div>
      <div class="modal-content">
        <div class="card mx-4">
          <div class="card-content has-text-centered">
            <div class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
              Confirm card selection
            </div>
            <!-- <div class="container has-text-centered mb-4">
              You will be brought to a page where you can use your new {{ $store.state.uiMods.powerup }}.
            </div> -->
            <div @click="showConfirmScreen = false" class="button is-light has-text-weight-semibold is-fullwidth mb-1">
              {{ t('Back') }}
            </div>
            <div @click="goToPowerupPage" class="button is-primary-colors has-text-weight-semibold is-fullwidth">
              {{ t('Confirm') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectPowerup',
  components: {
  },
  data () {
    return {
      showConfirmScreen: false,
      cards: [
        1, 2, 3, 4
      ]
    }
  },
  methods: {
    goToPowerupPage () {
      this.$router.push({ name: 'Powerups' })
    },
    getCardImage(index) {
      const defaultImages = [
        '../assets/powerups/card back blue.png',
        '../assets/powerups/card back orange.png',
        '../assets/powerups/card back purple.png',
        '../assets/powerups/card back red.png'
      ];
      const customImage = this.$store.state.adventureTeam.uiMods.powerupsCardBackingImageUrl
      return customImage || defaultImages[index]
    }
  },
  mounted() {
    if (!this.$store.state.showPowerupAlert) {
      this.$router.push({ name: 'Powerups' })
    }
  }
}
</script>

<style>
.cards-container {
  width: 100vw;
  max-width: 400px;
  padding: 0px 10px;
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.powerup-card {
  max-width: 100%;
  height: 250px;
  cursor: pointer;
  border-radius: 5px;
  object-fit: contain;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}
</style>
